@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

a {
  text-decoration-color: none;
  text-decoration: none;
  color: inherit;
  transition: all 0.3s ease-in-out;
}

a:hover,
a:focus,
a:visited,
a:link,
a:active {
  text-decoration: none;
}

.error {
  color: #c31526;
  font-weight: 500;
  font-size: 10px;
  line-height: 18px;
  padding: none;
  flex: 1;
}

.table-header {
  border: none;
  font-family: 'Poppins';
  color: white;
  background: #467DC0;
  border-bottom: none;
}

.cell-class {
  font-size: 12px !important;
}

.char-counter {
  font-size: 10px;
  display: flex;
  justify-content: flex-end;
}